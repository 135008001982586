import Recaptcha from 'react-google-recaptcha';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Message, Icon } from 'semantic-ui-react';
import Router, { useRouter } from 'next/router';

import { post } from 'utils/apiClient';
import styles from './investorSignUp.module.scss';

import { NewsletterPreferenceCheckbox, NewsletterPreferenceDropdown } from './NewsletterPreference';

  /**
   * The form is the sidebar signup.
   * 
   * For bespoke signup linked to a business use this: 
   * 
   * frontend/components/users/signup/InvestorSignUp.js
   * 
   */

const validAccountTypes = [
  'individual_investor'
];

function getAccountType(props) {
  if (props.query && props.query.accountType) {
    const accountType = props.query.accountType;
    if (validAccountTypes.includes(accountType)) {
      return accountType;
    }
  }

  return '';
}

export function InvestorSignUp(props) {
  const [accountType] = useState(getAccountType(props));
  const [inviteLink] = useState(props.query && props.query.invite);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newsletterSubscribe, setNewsletterSubscribe] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isBot, setBot] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [hasSubmitted, setSubmitted] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showCompanyNameError, setShowCompanyNameError] = useState(false);
  const [serverError, setServerError] = useState('');

  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [companyNameValid, setCompanyNameValid] = useState(true);

  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
  const [isPasswordNumber, setIsPasswordNumber] = useState(false);
  const [isPasswordLower, setIsPasswordLower] = useState(false);
  const [isPasswordUpper, setIsPasswordUpper] = useState(false);
  const [isPasswordSpecial, setIsPasswordSpecial] = useState(false);
  const [recaptchaResponseToken, setResponseToken] = useState(null);
  const recaptchaRef = useRef(null); 

  const router = useRouter();
  
  const handleSubmit = async () => {
    if (nameIsNotNull() && validateEmail() && validatePassword() && validateCompanyName() && !isBot) {
      
      const accType = accountType ? accountType : 'individual_investor';
      const emailAddress = email.trim();
      const response = await post(`/users/signupIndividualInvestor`, {
        username: emailAddress,
        password: password,
        accountType: accType,
        companyName: companyName,
        invite: inviteLink,
        firstName,
        lastName,
        newsletterSubscribe,
        recaptchaResponseToken,
        signupOrigin: "Organic"
      });

      if (!response.success) {
        setServerError(response.error);
        setIsSubmitting(false);
        recaptchaRef.current.reset();
        return;
      }

      props.handleSidebarConfigChange({open: false})
      // wait for sidebar closed callback to complete, then nav
      setTimeout(()=> { window.location = '/users/validate_email' }, 600)
    
    } else {
      // trigger client side validation
      setSubmitted(true);
    }

    setIsSubmitting(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  useEffect(() => {
    if (props.isAuthenticated) window.location ='/dashboard';
  }, [props]);

  useEffect(() => {
    if (hasSubmitted) {
      const passwordValid = validatePassword();
      if (!passwordValid) {
        setShowPasswordError(true);
      }

      const emailValid = validateEmail();
      if (!emailValid) {
        setShowEmailError(true);
      }

      if (accountType) {
        const companyNameValid = validateCompanyName();
        if (!companyNameValid) {
          setShowCompanyNameError(true);
        }
      }
    }
  }, [hasSubmitted, password, email, companyName]);

  const setIsNotBot = (responseToken) => {
    setResponseToken(responseToken);
    setBot(false);
  };

  const validateCompanyName = () => {
    if (accountType) {
      const valid = companyName.length > 1;
      setCompanyNameValid(valid);
      return valid;
    }
    return true;
  };

  const nameIsNotNull = () => {
    if (firstName === '' || lastName === '') {
      setShowNameError(true);
      return false;
    }
    return true;
  };

  const validateEmail = () => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = re.test(String(email).trim().toLowerCase());
    setEmailValid(valid);
    return valid;
  };

  const validatePassword = () => {
    const valid = validatePasswordStrength();
    setPasswordValid(valid);
    return valid;
  };

  const validatePasswordStrength = () => {
    const hasNumber = new RegExp(/[0-9]/).test(password);
    const hasLower = new RegExp(/[a-z]/).test(password);
    const hasUpper = new RegExp(/[A-Z]/).test(password);
    const hasSpecial = new RegExp(/[!#@$%^&*)(+=._-]/).test(password);

    setIsPasswordNumber(hasNumber);
    setIsPasswordLower(hasLower);
    setIsPasswordUpper(hasUpper);
    setIsPasswordSpecial(hasSpecial);

    if (!password) {
      return false;
    }

    const passwordLongEnough = password.length >= 8;
    setIsPasswordLongEnough(passwordLongEnough);

    if (!passwordLongEnough) {
      return false;
    }

    let count = 0;

    if (hasNumber) {
      count += 1;
    }

    if (hasLower) {
      count += 1;
    }

    if (hasUpper) {
      count += 1;
    }

    if (hasSpecial) {
      count += 1;
    }

    // must have 3 or more types (number, lower, upper, specical)
    if (count < 3) {
      return false;
    }

    return true;
  };

  return (
    <div className={styles["signup__container"]}>
      <div className={`${styles['signup__paragraph']} col-start-2 padding-top-3vh`}>
        <Form size="small" onSubmit={handleSubmit}>
          <div className={`${styles["core__heading"]} ${styles["signup__heading"]}`}>
            Sign up
            <div className={styles["signup__icon__container"]}>
              <Icon name={'cancel'} 
                size={'tiny'}
              // Close sidebar
              onClick={() => props.handleSidebarConfigChange({open: false})} 
              className={styles["signup__icon"]}/>
            </div>
          </div>
          <div className={`${styles["signup__title"]}`}>
            <h3>Investor account</h3>
          </div>
          {accountType && (
            <Form.Input
              error={hasSubmitted && !companyNameValid}
              success={hasSubmitted && companyNameValid}
              fluid
              icon="building outline"
              placeholder="Company Name"
              onChange={handleCompanyNameChange}
            />
          )}
            <Form.Input
              placeholder="First name"
              error={showNameError && firstName === ''}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <Form.Input
              placeholder="Last name"
              error={showNameError && lastName === ''}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <Form.Input
              error={hasSubmitted && !emailValid}
              placeholder="Email address"
              onChange={handleEmailChange}
            />
            <Form.Input
              error={hasSubmitted && !passwordValid}
              placeholder="Password"
              type="password"
              onChange={handlePasswordChange}
            />
            
          <NewsletterPreferenceDropdown selected={newsletterSubscribe} setSelected={setNewsletterSubscribe} />

          <div style={{ marginTop: 8, marginBottom: 8 }}>
            <Recaptcha 
              ref={recaptchaRef}
              sitekey="6LcNW68ZAAAAANM0Df2N0noDAY721GOSZVH2yTwh"
              onChange={setIsNotBot}
            />
          </div>
          <p className={`${styles["core__paragraph"]} ${styles["signup__disclaimer"]}`}>
            By clicking sign up, you agree to our{' '}
            <a target="_blank" href="https://cdn.catalist.co.nz/Documents/Investor+Terms+and+Conditions.pdf">
              investor terms and conditions
            </a>{' '}
            and our{' '}
            <a href="https://cdn.catalist.co.nz/Documents/Catalist+Privacy+Policy.pdf" target="_blank">
              privacy policy
            </a>
            .
          </p>
          <div className={styles["signup__button__container"]}>
            <Button
              style={{
                backgroundColor: '#0516d0',
                color: 'white',
              }}
              disabled={(hasSubmitted && !passwordValid) || !emailValid || isBot || newsletterSubscribe === null}
              loading={isSubmitting}
            >
              Sign up
            </Button>
          </div>
          {serverError && (
            <Message negative>
              <Message.Content>{serverError}</Message.Content>
            </Message>
          )}
          {showNameError && (
            <Message negative>
              <Message.Content>Please enter missing field(s).</Message.Content>
            </Message>
          )}
          {hasSubmitted && showCompanyNameError && !companyNameValid && (
            <Message negative>
              <Message.Content>Your company name isn't valid</Message.Content>
            </Message>
          )}
          {hasSubmitted && showEmailError && !emailValid && (
            <Message negative>
              <Message.Content>Your email address isn't valid</Message.Content>
            </Message>
          )}
          {hasSubmitted && showPasswordError && !passwordValid && (
            <Message negative>
              <Message.Content>Your password isn't valid</Message.Content>
              <Message.List>
                <Message.Item style={isPasswordLongEnough ? { color: 'green' } : null}>
                  Must have minimum 8 characters in length
                </Message.Item>
                <Message.Item>
                  Should contain 3 of the following:{' '}
                  <span style={isPasswordLower ? { color: 'green' } : null}>lower case</span>,{' '}
                  <span style={isPasswordNumber ? { color: 'green' } : null}>numbers</span>,{' '}
                  <span style={isPasswordUpper ? { color: 'green' } : null}>upper case</span>,{' '}
                  <span style={isPasswordSpecial ? { color: 'green' } : null}>special characters</span>.
                </Message.Item>
              </Message.List>
            </Message>
          )}
        </Form>
        <p className={`${styles["core__paragraph"]} ${styles["signup__accountlink_container"]}`}>
          Already have an account? <a href="/api/login">Log in here.</a>{' '}
        </p>
        
        <div className={`${styles["signup__title"]}`}>
          <hr style={{border: '0.5px solid #0d2d50'}}/>
          <h3>Business or investment group</h3>       
          <p className={`${styles["core__paragraph"]} ${styles["signup__accountlink_container"]}`}>
            Growth business or investment group interested in joining Catalist? 
            <br/>
            Click
            <a target="_blank" href="/Contact/List#Enquire"> here </a>
            to ask us to contact you.
          </p>
          
          <hr style={{border: '0.5px solid #0d2d50'}}/>
          <h3>Trust or company</h3>      
          <p className={`${styles["core__paragraph"]} ${styles["signup__accountlink_container"]}`}>
            Investing through a trust or company? 
            Contact us at <a href='mailto:hello@catalist.co.nz'>hello@catalist.co.nz</a> to discuss the steps for a trust or company.
          </p>
        </div>
      </div>
    </div>
  );
}
