import { Form } from 'semantic-ui-react';

const NEWSLETTER_OPTIONS = [{
	key: 'YES',
	text: "Yes, tell me about investment opportunities in growth businesses.",
	value: true,
},{
	key: 'NO',
	text: 'No, I don’t want to know about investment opportunities in growth businesses.',
	value: false,
}]

export function NewsletterPreferenceDropdown({ selected, setSelected}) {
	return (
		<Form.Dropdown
			placeholder='Tell me about investment opportunities on Catalist?'
			selection
			options={NEWSLETTER_OPTIONS}
			onChange={(_, data) => setSelected(data.value) }
			value={selected}
		/>
	)
}

export function NewsletterPreferenceCheckbox({ selected, setSelected }) {
	return (
		<Form.Checkbox
			style={{fontSize: '18px'}}
			label="I want to hear about new opportunities to invest in growth businesses."
			onChange={() => setSelected(!selected) }
			value={selected}
		/>
	)
}


